import React from 'react';
import { ContainerCartDetail } from '@components';
import { Layout, SEO } from '@componentsShared';
import { WrapperCart } from './styled';
import { graphql, useStaticQuery } from 'gatsby';
import { getMetadataPage } from '@utils/metadata';

const Cart = () => {
    const queryPageCarrito = useStaticQuery(graphql`
        query pageCarrito {
            allContentfulPaginaInternaFooter(filter: { page: { eq: "Carrito" } }) {
                nodes {
                    title
                    slug
                    metaData {
                        title
                        descripcion
                        keyWords
                        image {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const {
        nodes: [page]
    } = queryPageCarrito.allContentfulPaginaInternaFooter;

    return (
        <Layout showSummary="shoppingCart">
            <SEO {...getMetadataPage(page.metaData, page.slug)} index="noindex, nofollow" />
            <WrapperCart>
                {/* <ContainerTransaction></ContainerTransaction>  */}
                <ContainerCartDetail page={page} />
                {/* <PageToLoading titulo='Se está procesando tu pago' primaryText='La suerte está echada, espera unos segundos, que giren las balotas y ' strongText='¡a ganar!' /> */}
                {/* <ContainerTransaction></ContainerTransaction> */}
            </WrapperCart>
        </Layout>
    );
};
export default Cart;
